import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAscoVB1iwSpxXXGr6wDAgUgbDVBRgT8gs",
  authDomain: "crm-web-5cdb2.firebaseapp.com",
  projectId: "crm-web-5cdb2",
  storageBucket: "crm-web-5cdb2.appspot.com",
  messagingSenderId: "882900447826",
  appId: "1:882900447826:web:bb3662e7afb992570ea58b"
};

const apiUrl = process.env.REACT_APP_API_URL;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const saveTokenToServer = async (token, userID) => {
  // const userId = localStorage.getItem("user_id");

  if (!userID || !token) {
    console.error("User ID or Token is missing.");
    return;
  }

  const payload = { user_id: userID, token };

  try {
    const response = await fetch(`${apiUrl}/update_and_save_notification_for_web`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const result = await response.json();
    if (result.success) {
      console.log("Token saved successfully");
    } else {
      console.error("Error saving token", result);
    }
  } catch (error) {
    console.error("Error saving token to server:", error);
  }
};

export const requestPermission = (userID) => {
  console.log("User ID:", userID);

  if (Notification.permission === "granted") {
    console.log("Notification permission already granted.");
  }
  console.log("Requesting user permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return getToken(messaging, {
        vapidKey: "BD3rMBqbVKN2npwzLgtL2I88LJ3IPhagIQyjQDli1b-a0nMpO_ajNJ5Ty1M1oAFMWBPuBVgUycmI7k3_hJmwlyU",
      })
        .then((currentToken) => {
          if (currentToken) {
            saveTokenToServer(currentToken, userID);
            console.log("Client Token:", currentToken);
          } else {
            console.log("Failed to generate token.");
          }
        })
        .catch((err) => {
          console.error("Error retrieving token:", err);
        });
    } else {
      console.log("User permission denied.");
    }
  });
};

// Handle incoming messages when the app is in the background

onMessage(messaging, (payload) => {
  console.log('Foreground message received:', payload);
  const notificationTitle = payload.notification?.title || 'New Notification';
  const notificationOptions = {
    body: payload.notification?.body || 'You have a new message',
    icon: payload.notification?.icon || 'https://unsplash.com/photos/young-asian-travel-woman-is-enjoying-with-beautiful-place-in-bangkok-thailand-_Fqoswmdmoo',
  };

  if (Notification.permission === 'granted') {
    new Notification(notificationTitle, notificationOptions);
  }
});

// Listen for messages when the app is in the foreground
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Message received:', payload);
      resolve(payload);
    });
  });